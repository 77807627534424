import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const Title = () => (
  <div className="PgAVH-TS__Title PgAVH-TS-Title">
    <div className="container">
      <h2 className="PgAVH-TS-Title__title">
        Technical Support and User Loyalty
      </h2>
      <p className="PgAVH-TS-Title__subTitle">
        Honesty and reliability are important factors in establishing our
        partners' trust. Unfortunately, nothing is perfect — bugs and issues do
        happen. In these situations, the most important thing is how the company
        deals with these issues to help clients via tech support. The most
        transparent testaments can be seen from real users’ feedback. Here, we
        found some &nbsp;
        <TrackedLink
          class="PgAVH-TS-Title__link"
          category="amzscoutVsHelium10"
          action="Helium10"
          target
          path="https://www.trustpilot.com/review/www.helium10.com?sort=recency"
        >
          Helium 10
        </TrackedLink>
        &nbsp; and &nbsp;
        <TrackedLink
          class="PgAVH-TS-Title__link"
          category="amzscoutVsHelium10"
          action="AMZScout"
          target
          path="https://www.trustpilot.com/review/amzsc.amzgb.com?sort=recency"
        >
          AMZScout
        </TrackedLink>
        &nbsp; real feedback:
      </p>
    </div>
  </div>
);

export default Title;
